import React, { useEffect } from 'react';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

import '../index.css';
import '../styles/backup-zeroheight.css';
import '../node_modules/@digital-engineering-experiences/wb-webcomponents/dist/style.css';

function MyApp({
    Component,
    pageProps,
}: {
    Component: React.ComponentType<any>;
    pageProps: any;
}) {
    useEffect(() => {
        const connectionString =
            process.env.NEXT_PUBLIC_APPLICATIONINSIGHTS_CONNECTION_STRING;
        if (!connectionString || connectionString == '') return;

        const reactPlugin = new ReactPlugin();
        const appInsights = new ApplicationInsights({
            config: {
                disableCookiesUsage: true,
                disablePageUnloadEvents: ['unload'],
                connectionString: connectionString,
                extensions: [reactPlugin],
                extensionConfig: {
                    [reactPlugin.identifier]: {},
                },
            },
        });
        appInsights.loadAppInsights();
        appInsights.trackPageView();
    }, []);

    return <Component {...pageProps} />;
}

export default MyApp;
